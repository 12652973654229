import axios from 'axios';
import { showAlert } from './alert';

export const login = async (email, password) => {
    try {
        const res = await axios({
            method: 'POST',
            url: '/api/v1/user/login',
            data: {
                email,
                password
            }
        });
        if (res.data.status === 'success') {
            showAlert('success', 'Connexion réussie !');
             window.setTimeout(() => {
                location.assign('/accueilAdmin');
             }, 1500);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};

/* export const forgotPass = async () => {
    try {
        const res = await axios({
            method: 'POST',
            url: '/api/v1/users/forgotPassword',
            data: {
                email
            }
        });
        if (res.data.status === 'success') {
            showAlert('success', 'Un email vous a été envoyé !');
             window.setTimeout(() => {
                location.assign('/login');
             }, 1500);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};

export const logout = async ()=> {
    try {
        const res = await axios({
            method:'GET',
            url:'/api/v1/users/logout'
        });
        if(res.data.status === 'success') {
            location.assign('/');
        }

    } catch (err) {
        console.log(err)
        showAlert('error', 'Une erreur est survenue ! Merci de réessayer.');
    }
};

export const resetPassword = async (token,password, passwordConfirm) => {
    try {
        const res = await axios({
            method: 'PATCH',
            url: `/api/v1/users/resetPassword/${token}`,
            data: {
                password,
                passwordConfirm
            }
        });
        if (res.data.status === 'success') {
            showAlert('success', 'Connexion réussie !');
             window.setTimeout(() => {
                location.assign('/accueilAdmin');
             }, 1500);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
        console.log(err)
    }
}; */