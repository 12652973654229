import axios from 'axios';
import { showAlert } from './alert';

export const sendMail = async (name,email,sujet,message) => {
    try {
        const res = await axios({
            method: 'POST',
            url: '/api/v1/user/mailSend',
            data: {
                name,
                email,
                sujet,
                message,
            }
        });
        if (res.data.status === 'success') {
            showAlert('success', 'Mail Envoyé !');
            window.setTimeout(()=>{
                location.assign('/')
            },1500)
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};
