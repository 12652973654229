import axios from 'axios';
import { showAlert } from './alert';

export const createArticle = async (data) => {
    try {
        
        const url = `/api/v1/article/createArticle`
            
        const res = await axios({
            method: 'POST',
            url,
            data,
        });
        
        if (res.data.status === 'success') {
            showAlert('success', `Création de votre article réussis !`);
            window.setTimeout(()=>{
                location.assign('/accueilAdmin')
            },1500)
        }
    } catch (err) {
        showAlert('error', err);
    }
};