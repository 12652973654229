import '@babel/polyfill';
import {sendMail} from './sendMail'
import {login} from './login'
import {createArticle} from './gestionArticles'
const menuServices = document.querySelector('#servicesMenu')
const menuListServices = document.querySelector('.deroulant__list')
const infos = document.querySelector("#infos")
const formMail = document.querySelector('.form')
const formLogin = document.querySelector('.form--login')
const formCreateArticle = document.querySelector('#createArticle__form')

if(menuServices){
    menuServices.addEventListener('click',e=>{
        menuListServices.classList.toggle("show")
        menuListServices.style.setProperty('animation-name', 'moveInBottom');
        menuListServices.style.setProperty('animation-duration', '1s');
        menuListServices.style.setProperty('animation-timing-function', 'ease-out');
    
    })
}

if(infos){
    document.addEventListener('scroll',e=>{
        var posScroll = $(document).scrollTop();
        if(posScroll>0){
            infos.classList.add('sticky')
        }else{
            infos.classList.remove('sticky')
        }
    })
}

if(formMail){
    formMail.addEventListener('submit',e=>{
        e.preventDefault()
        const name = document.getElementById('name').value 
        const email = document.getElementById('email').value
        const sujet = document.getElementById('sujet').value
        const message = document.getElementById('message').value
        document.getElementById('spinner').classList.add('spinner')
        document.querySelector('.spinner-content').classList.add('spin-wrapper')
        sendMail(name,email,sujet,message)
    })
}

if(formLogin){
    const btnLogin = document.getElementById('login')
    btnLogin.addEventListener('click',e=>{
        e.preventDefault()
        const email = document.getElementById('email').value
        const password = document.getElementById('password').value
        login(email,password)
    })
}

var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ];
  

var options = {
    modules: {
      toolbar: toolbarOptions
    },
    theme: 'snow'
  };
if(document.getElementById('nonEdit')){
    console.log("POUET")
    const edit = document.querySelectorAll('.ql-editor')
    console.log(edit)
    for(let i=0;i<edit.length;i++){
        console.log("FOR")
        edit[i].setAttribute('contenteditable',false)
    }
}
if(document.querySelector('.navigation-secondary__list')){
    console.log(document.querySelectorAll('.ql-editor'))
    const edit = document.querySelectorAll('.ql-editor')
    for(let i=1;i<edit.length;i++){
        edit[i].classList.add('paragraph')
        //edit[i].classList.add('navigation-secondary__text')
        edit[i].classList.add('text-article-small')
    }
}

if(formCreateArticle){
    const createArticleBtn = document.querySelector('#createArticle')
    createArticleBtn.addEventListener('click',e=>{
        
        e.preventDefault()
        const data = new FormData()
    
        if(document.getElementById('imgHeader')){
            data.append('imgHeader',document.getElementById('imgHeader').files[0])
        }
        if(document.getElementById('titre')){
            data.append('titre',document.getElementById('titre').value)
        }
        if(document.getElementById('editor')){
            data.append('article',document.querySelector('#editor').innerHTML)
        }
        if(document.getElementById('img')){
            for(let i=0;i<document.getElementById('img').files.length;i++){
                data.append('img',document.getElementById('img').files[i])
            }
        }
        createArticle(data)
    })
}
